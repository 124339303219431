<template>
  <div class="head_top">
    <img src="../../../../public/images/二级海报/加盟-授权-二次.jpg" width="100%" height="100%" lazy>
  </div>
</template>

<script>
export default {
  name: "制定开发合作"
}
</script>

<style scoped>

</style>